import { Controller } from "stimulus";

import { decodeMails, copyText } from "../../util/mailsHelper";
import { isEmail } from "../../util/helpers";

export default class extends Controller {
  static targets = ["textContainer"];

  connect() {
    if (this.hasTextContainerTarget) {
      decodeMails(this.hasTextContainerTarget, this.textContainerTarget);
    }

    const linkElement = this.element.parentNode;
    if (this.element.parentNode && this.element.parentNode.hasAttribute("href")) {
      const href = linkElement.getAttribute("href");

      if (href.startsWith("mailto:") || isEmail(href) || href.startsWith("#")) {
        linkElement.addEventListener("click", this.actOnSpecificLink.bind(this));
      }
    }
  }

  actOnSpecificLink(event) {
    event.preventDefault();
    const linkElement = event.target;
    const href = linkElement.getAttribute("href");

    if (href.startsWith("mailto:") || isEmail(href)) {
      copyText(href.replace("mailto:", ""), linkElement);
    } else if (href.startsWith("#")) {
      this.anchorController = this.application.getControllerForElementAndIdentifier(document.body, "anchor");
      this.anchorController.scrollToAnchor(href);
    }
  }
}
