import { connectHits } from "instantsearch.js/es/connectors";
import Flickity from "flickity";
import "flickity-imagesloaded";
import "flickity/dist/flickity.css";
import { escapeHtml } from "../../../../util/helpers";

const renderHits = (renderOptions, isFirstRender) => {
  const { hits, results, widgetParams } = renderOptions;
  const { container, templates } = widgetParams;

  if (isFirstRender) {
    container.innerHTML = `
      <div class="ais-HitsCarousel">
        <div class="ais-HitsCarousel-list">
        </div>
      </div>`;
  }

  const listEl = container.querySelector(".ais-HitsCarousel-list");
  const flkty = Flickity.data(listEl);

  if (flkty) {
    flkty.destroy();
  }

  if (results && hits.length === 0) {
    const { query } = results;
    listEl.innerHTML = `<div class="ais-HitsCarousel--empty">${templates.empty.replace("{{ query }}", escapeHtml(query))}</div>`;
  } else {
    listEl.innerHTML = hits
      .map(
        hit =>
          `<div class="ais-HitsCarousel-item">
            ${templates.item(hit)}
          </div>`
      )
      .join('');

    if (!isFirstRender) {
      new Flickity(listEl, {
        cellAlign: 'left',
        contain: true,
        groupCells: true,
        pageDots: false,
      });
    }
  }
};

export default connectHits(renderHits);
