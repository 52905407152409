/* global gon */
import { Controller } from "stimulus";
import "instantsearch.css/themes/reset.css";
import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import { configure, index, searchBox, stats } from "instantsearch.js/es/widgets";
import { connectHits } from "instantsearch.js/es/connectors";
import hitsTemplates from "../hits/templates";
import icon from "../../icon/helper";
import hitsCarousel from "./widgets/hits_carousel";
import RecentSearches from "recent-searches";
import { escapeHtml } from "../../../util/helpers";
import "wicg-inert";

export default class extends Controller {
  static targets = ["box", "container", "hits", "recent", "row"];

  initialize() {
    this.headerController = this.application.getControllerForElementAndIdentifier(document.querySelector(".paris-header"), "header")
    this.mediaQueryList = window.matchMedia("(max-width: 799px)");
    this.initialized = false;
    this.isOpen = false;
    this.canReorder = false;
    this.activeElement = null;

    this.initAlgolia();
    this.initWidgets();
    this.initRecentSearches();

    if (this.isSmallAndMedium) this.initModal();
  }

  connect() {
    document.body.addEventListener("focus", this.getActiveElement, true);

    if (this.isLarge) this.element.inert = true;
  }

  disconnect() {
    this.disposeModal();
  }

  toggle(force = null, fromHeader) {
    if (force) this.isOpen = !force;
    this.isOpen ? this.close(null, fromHeader) : this.open();
  }

  open(e) {
    if (e) e.preventDefault();
    this.storeOpener();
    this.element.classList.add("is-open");
    if (this.isLarge) this.initModal();
    this.initClick();
    this.initKeyboard();
    if (this.isLarge) {
      this.initScroll();
      this.element.inert = false;
    }
    this.initFocus();

    if (this.searchBoxInput) {
      this.saveRecentSearchFn = (e) => this.saveRecentSearch(e.target.value);
      this.searchBoxInput.addEventListener("blur", this.saveRecentSearchFn);
    }

    this.isOpen = true;
    this._trackingContentSquare(true);
  }

  close(e,fromHeader) {
    this.element.classList.remove("is-open");
    this.disposeClick();
    this.disposeKeyboard();
    this.disposeFocus();
    if (!fromHeader) this.headerController.toggleSearchButton();
    if (this.isLarge) {
      this.disposeScroll();
      this.element.inert = true;
    }

    if (this.searchBoxInput) {
      this.searchBoxInput.removeEventListener("blur", this.saveRecentSearchFn);
    }

    this.isOpen = false;
    this._trackingContentSquare();
  }

  initModal() {
    if (this.initialized) return;

    this.search.start();

    this.initialized = true;

    const inputFocusEvent = new Event("search-modal:input-focus");
    this.searchBoxInput.addEventListener("focus", () => this.element.dispatchEvent(inputFocusEvent));
  }

  disposeModal() {
    this.search.dispose();

    this.initialized = false;
  }

  initClick() {
    this.defaultSearchPath = this.element.dataset.defaultSearchPath;
    this.onClickFn = (e) => this.onClick(e);
    document.addEventListener("click", this.onClickFn);
  }

  disposeClick() {
    document.removeEventListener("click", this.onClickFn);
  }

  onClick(e) {
    // When clicking outside the modal, close it
    if (e.target == this.element) {
      this.close();
      return;
    }

    // When clicking on search box submit button, go to the search page
    if (this.boxTarget.contains(e.target) && e.target.closest(".ais-SearchBox-submit")) {
      window.location.href = this.defaultSearchPath;
    }
  }

  initKeyboard() {
    document.onkeydown = (e) => {
      const event = e || window.event;

      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
      } else if (event.key === "Escape") {
        this.close();
      }
    };
  }

  disposeKeyboard() {
    document.onkeydown = null;
  }

  initFocus() {
    document.addEventListener("focus", this.trapFocus.bind(this), true);
    this.focusToSearchBox();
    this.containerTarget.scrollTop = 0;
  }

  disposeFocus() {
    document.removeEventListener("focus", this.trapFocus.bind(this), true);

    if (this.opener) {
      this.opener.focus();
      this.opener = null;
    }
  }

  focusToSearchBox() {
    const input = this.searchBoxInput;
    if (!input) return;
    input.focus();
  }

  trapFocus(e) {
    if (this.element.contains(e.target)) {
      this.lastFocus = e.target;
      return;
    }

    if (this.lastFocus === this.lastFocusableElement) {
      e.preventDefault();
      this.focusToSearchBox();
    } else if (this.lastFocus === this.firstFocusableElement) {
      e.preventDefault();
      this.lastFocusableElement.focus();
    }
  }

  initScroll() {
    this.containerTarget.scrollTop = 0;
    document.body.classList.add("has-disabled-scroll");
  }

  disposeScroll() {
    document.body.classList.remove("has-disabled-scroll");
  }

  storeOpener() {
    // store the current focused element before focusing the layer
    this.opener = this.opener || this.activeElement || document.activeElement;
  }

  countHitsByIndex() {
    this.hitsCountByIndex = [];

    this.rowTargets.forEach((row) => {
      const indexKey = row.dataset.searchIndexKey;
      const hitsCountEl = row.querySelector(".ais-Stats-text");

      if (hitsCountEl) {
        const hitsCount = parseInt(hitsCountEl.textContent.replace(" ", ""), 10);
        this.hitsCountByIndex.push({ row: row, indexKey: indexKey, hitsCount: hitsCount });
      }
    });
  }

  reorderHits() {
    if (!this.canReorder) {
      this.rowTargets.forEach((row) => {
        row.style.removeProperty("order");
      });
      return;
    }

    this.hitsCountByIndex
      .sort((a, b) => b.hitsCount - a.hitsCount)
      .forEach((i, index) => {
        i.row.style.order = index;
      });
  }

  initAlgolia() {
    this.indexKey = "info";
    this.indices = {};

    const searchClient = algoliasearch(gon.algolia.application_id, this._determineSearchAPIKey());

    this.search = instantsearch({
      indexName: this.indexName,
      numberLocale: this.currentLocale,
      routing: false,
      searchFunction: (helper) => {
        this.query = helper.state.query;

        // Hide content until the user starts typing
        if (this.query) {
          this.element.classList.add("is-searching");

          this.canReorder = this.query.length >= 3;
        } else {
          this.element.classList.remove("is-searching");
          this.renderRecentSearches();
        }

        helper.search();
      },
      searchClient,
    });

    this.search.on("render", () => {
      this.countHitsByIndex();
      this.reorderHits();
      this.updateSearchLinks();
      this.updateAccessibility();
    });
  }

  initWidgets() {
    this.search.addWidgets([
      searchBox({
        autofocus: this.indexKey === "content",
        container: this.boxTarget,
        placeholder: this.boxTarget.getAttribute("data-placeholder"),
        showLoadingIndicator: false,
        templates: {
          reset: icon("close"),
          submit: icon("search"),
        },
      }),
    ]);

    this.rowTargets.forEach((row) => {
      const indexKey = row.dataset.searchIndexKey;

      this.addWidgetsToIndex(indexKey, row);
    });
  }

  addWidgetsToIndex(indexKey, row) {
    this.indices[indexKey] = {};

    const indexWidget = index({
      indexName: gon.algolia.indexes[indexKey].name,
      indexId: indexKey,
    });

    this.search.addWidgets([indexWidget]);

    let widgets = [];

    const statsContainer = row.querySelector(".paris-search-modal-row-count");
    if (statsContainer) {
      widgets.push(
        stats({
          container: statsContainer,
          templates: {
            text: (data) => `${this.formattedNumber(data.nbHits)} résultat${data.nbHits > 1 ? "s" : ""}`,
          },
        })
      );
    }

    // TODO: improve this
    if (indexKey === "mairies") {
      const structuredResults = connectHits(({ hits, widgetParams }) => {
        const result = hits[0];
        const { container, templates } = widgetParams;

        if (result && this.search.helper && result._rankingInfo.nbExactWords >= 1) {
          // Render the result
          container.innerHTML = templates.item(result);
          return;
        }

        // Render nothing
        container.innerHTML = "";
      });

      widgets.push(
        structuredResults({
          container: row.querySelector(".paris-search-modal-hits"),
          templates: hitsTemplates.modal[indexKey],
        })
      );

      widgets.push(
        configure({
          page: 0,
          hitsPerPage: 1,
          getRankingInfo: true,
        })
      );
    } else {
      widgets.push(
        hitsCarousel({
          container: row.querySelector(".paris-search-modal-hits"),
          templates: hitsTemplates.modal[indexKey],
        })
      );
    }

    widgets.push(
      configure({
        analyticsTags: [`mairie:${gon.mairie.id || "global"}`],
        hitsPerPage: 10,
      })
    );

    if (gon.algolia.indexes[indexKey].distinct) {
      widgets.push(
        configure({
          distinct: true,
        })
      );
    }

    indexWidget.addWidgets(widgets);

    this.indices[indexKey].indexWidget = indexWidget;
    this.indices[indexKey].widgets = widgets;
  }

  initRecentSearches() {
    this.recentSearches = new RecentSearches({
      ttl: 1000 * 3600 * 24 * 365, // 1 year
      limit: 5,
      ranking: "TIME",
    });

    this.renderRecentSearches();
  }

  renderRecentSearches() {
    const items = this.recentSearches.getRecentSearches();

    this.element.classList.toggle("has-recent", items.length > 0);

    this.recentTarget.innerHTML = items
      .map(
        (item) => `
      <li class="paris-search-modal-initial-item">
        <button class="paris-search-modal-initial-item-button" data-action="search-modal#setQuery keydown->search-modal#setQueryWithKeyboard">${icon(
          "clock2"
        )}${item.query}</button>
      </li>`
      )
      .join("");
  }

  saveRecentSearch(query) {
    if (query === "") return;
    this.recentSearches.setRecentSearch(escapeHtml(query), {});
  }

  setQuery(e) {
    e.preventDefault();
    const query = e.target.innerText;
    this.search.helper.setQuery(query).search();
    if (e.target.dataset.type != "spotlight") this.saveRecentSearch(query);
  }

  setQueryWithKeyboard(e) {
    if (e.keyCode == 13) {
      this.setQuery(e);
    }
  }

  updateSearchLinks() {
    this.rowTargets.forEach((row) => {
      // Scroll hits to top
      // this.hitsTargets[i].scrollTop = 0;

      const indexKey = row.dataset.searchIndexKey;

      if (indexKey == "mairies" || !this.hitsCountByIndex.length) return;

      const more = row.querySelector(".paris-search-modal-row-more");
      const links = row.querySelectorAll(".paris-search-modal-row-count, .paris-search-modal-row-more-link");

      const hitsCount = this.hitsCountByIndex.find((h) => h.indexKey === indexKey).hitsCount;

      // Display more only if there are hits
      more.classList.toggle("is-visible", hitsCount > 0);

      // Update links href
      const base = row.dataset.searchPath;
      const href = this.searchPath(base, this.query);
      links.forEach((link) => link.setAttribute("href", href));

      // Store search page path in case the user clicks on submit button
      if (indexKey == "info") this.defaultSearchPath = href;
    });
  }

  updateAccessibility() {
    // Update searchBox buttons titles
    this.element.querySelector('.ais-SearchBox-submit').setAttribute('title', 'Rechercher');
    this.element.querySelector('.ais-SearchBox-reset').setAttribute('title', 'Réinitialiser');
  }

  searchPath(base, query) {
    let params = [];
    if (query) params.push(`q=${query}`);
    return `${base}${params.length ? `?${params.join("&")}` : ""}`;
  }

  getActiveElement(event) {
    if (event.target !== document.body) {
      this.activeElement = event.target;
    }
  }

  formattedNumber(number) {
    if (!this.numberFormatter) {
      this.numberFormatter = new Intl.NumberFormat(this.currentLocale);
    }

    return this.numberFormatter.format(number);
  }

  get focusableElements() {
    return this.containerTarget.querySelectorAll(
      "a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled])"
    );
  }

  get firstFocusableElement() {
    const focusableElements = this.focusableElements;
    return focusableElements[0];
  }

  get lastFocusableElement() {
    const focusableElements = this.focusableElements;
    return focusableElements[focusableElements.length - 1];
  }

  get currentLocale() {
    return document.documentElement.lang;
  }

  get indexName() {
    return gon.algolia.indexes[this.indexKey].name;
  }

  get availableIndexKeys() {
    return ["info", "places", "sortir", "activities"];
  }

  get searchBoxInput() {
    if (!this.hasBoxTarget) return null;
    return this.boxTarget.querySelector("input");
  }

  get isSmallAndMedium() {
    return this.mediaQueryList.matches;
  }

  get isLarge() {
    return !this.isSmallAndMedium;
  }

  _trackingContentSquare(open) {
    let url = window.location.pathname + window.location.hash.replace("#", "?__");

    if (open) {
      url += "?cs-popin-searchBar";
    }

    window._uxa = window._uxa || [];
    window._uxa.push(["trackPageview", url]);
  }

  _determineSearchAPIKey(){
    if(gon.mairie.id === 'global' || gon.mairie.id === null){
      return gon.algolia.global_scoped_api_key
    }
    return gon.algolia.scoped_api_key
  }
}

