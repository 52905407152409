import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["toggleButton", "menu", "closeButton"];

  initialize() {
  }

  connect() {
    this.opened = false;
  }

  open(e) {
    if (this.opened) return;
    const toggleButton = e.target.closest("button");
    const menu = toggleButton.nextElementSibling;
    this.opened = toggleButton;
    toggleButton.classList.add("is-open");
    toggleButton.setAttribute("aria-expanded", "true");

    const firstFocusableElement = this.firstFocusableElement(menu);
    // Do not autofocus <select> because it opens the dropdown on mobile
    if (firstFocusableElement.nodeName != "SELECT") {
      firstFocusableElement.focus();
    }
  }

  close() {
    if (!this.opened) return;
    const toggleButton = this.opened;
    this.opened = false;
    toggleButton.classList.remove("is-open");
    toggleButton.setAttribute("aria-expanded", "false");
    toggleButton.focus();
  }

  toggle(e) {
    if (this.opened) {
      this.close(e);
    } else {
      this.open(e);
    }
  }

  firstFocusableElement(container) {
    return container.querySelector(
      "a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled])"
    );
  }
}
