/* global gon */
import { Controller } from "stimulus";
import "instantsearch.css/themes/reset.css";
import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import { configure, hits, index, pagination, /*refinementList,*/ searchBox, stats } from "instantsearch.js/es/widgets";
import { history } from "instantsearch.js/es/lib/routers";
import hitsTemplates from "./hits/templates";
import filtersWidgets from "./filters/widgets";
import forcedFacet from "./filters/widgets/forced_facet";
import sortByRadios from "./filters/widgets/sort_by_radios";
import hitsWidgets from "./hits/widgets";
import addNumbersToItems from "./hits/map/add_numbers_to_items";
import icon from "../icon/helper";
import moment from "moment";

// import { resolve } from "promise-polyfill";
moment.locale("fr");

export default class extends Controller {
  static targets = [
    "box",
    "content",
    "filters",
    "filtersContainer",
    "filtersTitle",
    "indexButton",
    "hits",
    "hitsCount",
    "map",
    "miniMap",
    "pagination",
    "refinements",
    "sorting",
    "sortingTitle",
    "topHits",
  ];

  initialize() {
    this.mediaQueryList = window.matchMedia("(max-width: 799px)");
    this.initAlgolia();
  }

  connect() {}

  disconnect() {
    this.search.dispose();
  }

  initAlgolia() {
    this.indexKey ||= this.data.get("index") || "everything";
    this.element.classList.toggle("has-map", this.hitsOnMap);
    this.indices = {};
    this.searchClient = algoliasearch(gon.algolia.application_id, this._determineSearchAPIKey());
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const placeSlug = urlParams.get("lieu") || "";
    const client = algoliasearch(gon.algolia.application_id, gon.algolia.global_api_key);
    const searchIndex = client.initIndex(this.indexName);

    if (this.indexKey === "everything") {
      const activeIndexEl = this.indexButtonTargets[0];
      activeIndexEl.classList.add("is-active");
      activeIndexEl.setAttribute("title", `${activeIndexEl.innerText} – rubrique active`);
    }

    searchIndex
      .search(placeSlug, {
        attributesToRetrieve: ["searchable_place"],
      })
      .then((hits) => {
        this.placeName = hits.hits[0]?.searchable_place;
        this._search();
        this._addWidgetsToIndex();
      });
  }

  changeIndex(e) {
    e.preventDefault();
    this._resetIsActive();
    const activeIndexEl = e.target;

    activeIndexEl.classList.add("is-active");
    activeIndexEl.setAttribute("title", `${activeIndexEl.innerText} – rubrique active`);

    this._removeWidgetsFromIndex();
    const newIndexKey = activeIndexEl.closest("[data-index-key]").dataset.indexKey;
    this.element.setAttribute("data-search-index", newIndexKey);
    this.indexKey = newIndexKey;
    this.element.classList.toggle("has-map", this.hitsOnMap);
    this.search.dispose();
    this.searchClient = algoliasearch(gon.algolia.application_id, this._determineSearchAPIKey());
    this._search(true);
    this._addWidgetsToIndex(activeIndexEl);
  }

  enterHit(e) {
    const hitNumber = e.target.dataset.hitNumber;
    const marker = this.getMapMarker(hitNumber);
    if (!marker) return;
    marker.classList.add("is-active");
  }

  formattedNumber(number) {
    if (!this.numberFormatter) {
      this.numberFormatter = new Intl.NumberFormat(this.currentLocale);
    }
    return this.numberFormatter.format(number);
  }

  getMapMarker(hitNumber) {
    return document.querySelector(`.leaflet-marker-icon.paris-search-hits-item-number[data-hit-number="${hitNumber}"]`);
  }

  hideEl(element) {
    [element].flat().forEach((el) => (el.style.display = "none"));
  }

  indexNameForKey(indexKey, suffix = "") {
    return gon.algolia.indexes[indexKey].name + suffix;
  }

  leaveHit(e) {
    const hitNumber = e.target.dataset.hitNumber;
    const marker = this.getMapMarker(hitNumber);
    if (!marker) return;
    marker.classList.remove("is-active");
  }

  showEl(element, display = "block") {
    [element].flat().forEach((el) => (el.style.display = display));
  }

  get availableIndexKeys() {
    return gon.search.available_index_keys;
  }

  get categoriesTable() {
    return gon.categories;
  }

  get currentIndexKey() {
    return this.indexKey;
  }

  get currentLocale() {
    return document.documentElement.lang;
  }

  get defaultFacetFilters() {
    return (gon.search && gon.search.defaultFacetFilters) || {};
  }

  get disciplinesTable() {
    return gon.disciplines;
  }

  get political_groups() {
    return gon.political_groups;
  }

  get hitsOnMap() {
    return gon.algolia.indexes[this.indexKey].hits_on_map === true;
  }

  get indexName() {
    return gon.algolia.indexes[this.indexKey].name;
  }

  get indexRouteKey() {
    return gon.algolia.indexes[this.indexKey].route_key;
  }

  get indexRouteKeys() {
    return Object.values(gon.algolia.indexes)
      .map((item) => item.route_key)
      .filter(Boolean);
  }

  get isSmallAndMedium() {
    return this.mediaQueryList.matches;
  }

  get tagsTable() {
    return gon.tags;
  }

  get showTopHits() {
    return gon.algolia.indexes[this.indexKey].show_top_hits === true;
  }

  get universesTable() {
    return gon.universes;
  }

  _addFilterWidgets() {
    this._toggleFilterContainer();

    let lastIndex = filtersWidgets[this.indexKey].length - 1
    // Holds the last fieldset element created
    let lastTitle = null

    // Add filters for current index
    filtersWidgets[this.indexKey].forEach((filter, index) => {
      if (filter.widget) {
        const filterEl = document.createRange().createContextualFragment(`
          <div class="search-filters-item ${filter.modifiers || ""}">
            ${filter.title ? `<div class="search-filters-item-title">${filter.title}</div>` : ""}
            <div class="search-filters-item-widget"></div>
          </div>
        `);
        const container = filterEl.querySelector(".search-filters-item-widget");
        // If there fieldset existing, adds the widget to it
        if (lastTitle) {
          lastTitle.appendChild(filterEl)
        } else {
          this.filtersTarget.appendChild(filterEl);
        }
        // search.addWidgets([
        //   instantsearch.widgets.configure({
        //     distinct: 1,
        //   }),
        this.search.addWidget(filter.widget(container));
      } else if (filter.title) {

        // If there is already a fieldset waiting to be injected, inject it and empty the variable
        if (lastTitle) {
          this.filtersTarget.appendChild(lastTitle);
          lastTitle = null
        }

        const fieldset = document.createElement('fieldset')
        const titleEl = document.createRange().createContextualFragment(`
            <legend class="search-filters-item-title">${filter.title}</legend>
        `);
        fieldset.appendChild(titleEl)
        lastTitle = fieldset
      }

      // If last item of the loop and we still have a fieldset to inject, inject it
      if (lastTitle && index == lastIndex) {
        this.filtersTarget.appendChild(lastTitle)
        lastTitle = null
      }
    });
  }

  _addNbHitsWidgets() {
    if (this.indexKey !== "electeds") {
      const indexNbhits = this.availableIndexKeys
        .filter((item) => item !== "electeds")
        .map((indexKey) => {
          const indexWidget = index({
            indexName: gon.algolia.indexes[indexKey].name,
            indexId: indexKey,
          });
          const indexButton = this.indexButtonTargets.find((el) => el.dataset.indexKey === indexKey);
          return indexWidget.addWidgets([
            stats({
              container: indexButton.querySelector(".search-filters-indices-count"),
              templates: {
                text: (data) => this.formattedNumber(data.nbHits),
              },
            }),
          ]);
        });
      this.search.addWidgets(indexNbhits);
    }
  }

  _addWidgetsToIndex() {
    const sortableBy = gon.algolia.indexes[this.indexKey].sortable_by;

    //Add common widgets
    this.search.addWidgets([
      searchBox({
        autofocus: this.indexKey === "content",
        container: this.boxTarget,
        placeholder: this.boxTarget.getAttribute("data-placeholder"),
        showLoadingIndicator: false,
        templates: {
          reset: icon("close"),
          submit: icon("search"),
        },
      }),
      hits({
        ...(this.hitsOnMap ? { transformItems: addNumbersToItems } : {}),
        container: this.hitsTarget,
        templates: hitsTemplates[this.indexKey],
      }),
      configure({
        analyticsTags: [`mairie:${gon.mairie.id || "global"}`],
        hitsPerPage: 30,
        distinct: 1,
        facetingAfterDistinct: true,
        attributesToSnippet: "content:30",
      }),
      stats({
        container: this.hitsCountTarget,
        templates: {
          text: (data) => `${this.formattedNumber(data.nbHits)} résultat${data.nbHits > 1 ? "s" : ""}`,
        },
      }),
      pagination({
        container: this.paginationTarget,
        padding: 2,
        scrollTo: this.boxTarget,
        showFirst: false,
        showLast: false,
        templates: {
          previous: icon("arrow-left"),
          next: icon("arrow-right"),
        },
      }),
    ]);

    if (sortableBy) {
      this.search.addWidgets([
        sortByRadios({
          container: this.sortingTarget,
          items: [
            {
              key: "pertinence",
              label: "pertinence",
              index: this.indexNameForKey(this.indexKey),
            },
            ...sortableBy.map((key) => {
              return {
                key,
                label: key,
                index: this.indexNameForKey(this.indexKey, `_sort_by_${key}`),
              };
            }),
          ],
        }),
      ]);
    }

    //Add forcedfacet for elected depending on the mandate
    Object.keys(this.defaultFacetFilters).forEach((attribute) => {
      const value = this.defaultFacetFilters[attribute];
      this.search.addWidgets([
        forcedFacet({
          attribute,
          value,
        }),
      ]);
    });

    //Add nbHits widget
    this._addNbHitsWidgets();

    //Add current index specific filterWidgets
    this._addFilterWidgets();

    // Add hits widgets for current index & map
    // from config: frontend/components/search/hits/widgets.js
    hitsWidgets[this.indexKey].forEach((filter) => {
      const target =
        this.isSmallAndMedium && filter.mobileTarget ? `${filter.mobileTarget}Target` : `${filter.target}Target`;
      this.search.addWidget(filter.widget(this[target]));
    });
  }

  _determineSearchAPIKey() {
    if (gon.mairie.id === "global" || gon.mairie.id === null) {
      return gon.algolia.global_scoped_api_key;
    }
    const isScoped =
      this.indexRouteKey === "tout" || this.indexRouteKey === "infos" || this.indexRouteKey === "electeds";
    const apiKey = isScoped ? gon.algolia.scoped_api_key : gon.algolia.global_scoped_api_key;
    return apiKey;
    // return gon.algolia.global_scoped_api_key;
    // // return gon.algolia.scoped_api_key;
  }

  _formatArray(arr) {
    return arr
      ?.filter((item) => item !== "")
      .filter((item) => item !== "undefined")
      .filter((item) => item !== undefined);
  }

  _formatedDate(date, type) {
    if (!date) return;
    const dateArr = date.toString().split(":");

    if (type === "timestamp") {
      return this._isUnique(dateArr)
        ? `${moment.unix(dateArr[0]).format("DD-MM-YYYY")}`
        : `${moment.unix(dateArr[0]).format("DD-MM-YYYY")}:${moment.unix(dateArr[1]).format("DD-MM-YYYY")}`;
    }
    const start = moment(dateArr[0], "DD-MM-YYYY");
    const end = this._isUnique(dateArr) ? start : moment(dateArr[1], "DD-MM-YYYY");
    return `${moment(start).format("X")}:${moment(end).format("X")}`;
  }

  _getAudienceSlug(publics) {
    const publicsArr = [];
    if (publics.petits === true) {
      publicsArr.push("tout-petits");
    }
    if (publics.enfants === true) {
      publicsArr.push("enfants");
    }
    if (publics.jeunes === true) {
      publicsArr.push("jeunes");
    }
    if (publics.adultes === true) {
      publicsArr.push("adultes");
    }
    return publicsArr.join("+");
  }

  _getAudienceParsedQuery(audience) {
    const parsedObject = {
      petits: audience.includes("tout-petits") ? true : false,
      enfants: audience.includes("enfants") ? true : false,
      jeunes: audience.includes("jeunes") ? true : false,
      adultes: audience.includes("adultes") ? true : false,
    };
    return parsedObject;
  }

  _getCurrentTownHallIdSlug() {
    if (gon.mairie.id === 1234) {
      return "Paris Centre";
    }
    return `${gon.mairie.id}ᵉ arrondissement`;
  }

  _getBaseUrl(url) {
    // Split the URL into parts
    const parts = url.split("/");

    // Find the index of the part that matches a key
    const index = parts.findIndex((part) => {
      // Remove any query parameters from the part
      const mainPart = part.split("?")[0];
      // Check if the cleaned part matches any key
      return this.indexRouteKeys.includes(mainPart);
    });

    // If we found a matching part, then we will slice off that part and anything after it
    // If not, we'll leave the parts as they are
    const newParts = index !== -1 ? parts.slice(0, index) : parts;

    // Join the parts back together into a URL
    const newUrl = newParts.join("/");

    // If the URL doesn't end with a slash or a query string, we'll add a slash to the end
    const formattedUrl = newUrl.endsWith("/") || newUrl.includes("?") ? newUrl : newUrl + "/";

    return formattedUrl;
  }

  _getDisciplineNames(disciplines) {
    // Define as an array but is a string when parsing url
    const names = [];
    if (!Array.isArray(disciplines)) {
      disciplines?.split(" ").forEach((slug) => {
        names.push(Object.keys(this.disciplinesTable).find((key) => this.disciplinesTable[key] === slug));
      });
    }
    return names;
  }

  _getDisciplineSlug(disciplines) {
    const slugs = disciplines?.map((item) => {
      return this.disciplinesTable[item];
    });
    return slugs.join("+");
  }

  _getDistrictName(districts) {
    const names = [];
    if (!Array.isArray(districts)) {
      districts?.split(" ").forEach((item) => {
        if (item.includes("Paris")) {
          names.push(item.split("-").join(" "));
          return;
        } else if (item.includes("750")) {
          const baseDistrict = "ᵉ arrondissement";
          item[item.length - 2] === "0"
            ? names.push(item[item.length - 1] + baseDistrict)
            : names.push(item.slice(-2) + baseDistrict);
          return;
        }
        names.push(item);
      });
    }
    return names;
  }

  _getDistrictSlug(districts) {
    const baseDistrict = "750";
    const slugs = districts?.map((item) => {
      if (item.includes("Paris")) {
        return item.split(" ").join("-");
      } else if (item.includes("arrondissement")) {
        const districtNumber = item.split(" ")[0].slice(0, -1);
        districtNumber.length < 2
          ? (item = baseDistrict + "0" + districtNumber)
          : (item = baseDistrict + districtNumber);
        return item;
      }
      return item;
    });
    return slugs.join("+");
  }

  _getGroupNames(groupes) {
    const names = [];
    if (!Array.isArray(groupes)) {
      groupes?.split(" ").forEach((slug) => {
        if (slug === "groupe-paris-en-commun") {
          names.push("Groupe Paris en commun ");
          return;
        }
        names.push(Object.keys(this.political_groups).find((key) => this.political_groups[key] === slug));
      });
    }
    return names;
  }
  _getGroupSlug(groupes) {
    const slugs = groupes?.map((item) => {
      return this.political_groups[item];
    });
    return slugs.join("+");
  }

  _getTagNames(tags) {
    // Define as an array but is a string when parsing url
    const names = [];
    if (!Array.isArray(tags)) {
      tags?.split(" ").map((slug) => {
        names.push(Object.keys(this.tagsTable).find((key) => this.tagsTable[key] === slug));
      });
    }
    return names;
  }

  _getTagSlug(tags) {
    const slugs = tags?.map((item) => {
      return this.tagsTable[item];
    });
    return slugs.join("+");
  }

  _getTypeNames(types) {
    // Define as an array but is a string when parsing url
    const names = [];
    if (!Array.isArray(types)) {
      types?.split(" ").map((slug) => {
        names.push(Object.keys(this.categoriesTable).find((key) => this.categoriesTable[key] === slug));
      });
    }
    return names;
  }

  _getTypeSlug(types) {
    const slugs = types?.map((item) => {
      return this.categoriesTable[item];
    });
    return slugs.join("+");
  }
  _getUniverseNames(universes) {
    // Define as an array but is a string when parsing url
    const names = [];
    if (!Array.isArray(universes)) {
      universes?.split(" ").map((slug) => {
        names.push(Object.keys(this.universesTable).find((key) => this.universesTable[key] === slug));
      });
    }
    return names;
  }

  _getUniverseSlug(universes) {
    const slugs = universes?.map((item) => {
      return this.universesTable[item];
    });
    return slugs.join("+");
  }

  _isUnique(dateArr) {
    if (dateArr.length === 1) return true;
    return dateArr[0] === dateArr[1];
  }

  _removeWidgetsFromIndex() {
    while (this.filtersTarget.firstChild) {
      this.filtersTarget.removeChild(this.filtersTarget.firstChild);
    }
  }

  _replaceSpacesByPlus(item) {
    return item.split(" ").join("+");
  }

  _resetIsActive() {
    this.indexButtonTargets.forEach((item) => {
      item.classList.remove("is-active");
      item.removeAttribute("title");
    });
  }

  _toggleFilterContainer() {
    // Hide filters container if there are no filters

    // In "./filters/widgets" we added the common.free widget to everything and info index to fix nbhits
    // Otherwise, we noticed a bug: on a given query,
    // the nbhits of the everything and info indexes could change when we switched to the other indexes.
    // Here we hide the filterContainer for ever
    if (this.indexKey === "everything" || this.indexKey === "info") {
      this.filtersContainerTarget.style.display = "none";
      return;
    }
    this.filtersContainerTarget.style.display = filtersWidgets[this.indexKey].length === 0 ? "none" : "block";
  }

  _topHitsNumber(isToggleable) {
    this.element.classList.toggle("is-searching", isToggleable);

    // if (this.showTopHits && !query) {
    //   if (this.hasTopHitsTarget) {
    //     this.showEl(this.topHitsTarget);
    //     this.hideEl([this.hitsTarget, this.hitsCountTarget, this.contentTarget, this.paginationTarget]);
    //   }
    // } else {
    //   if (this.hasTopHitsTarget) {
    //     this.hideEl(this.topHitsTarget);
    //     this.showEl([this.hitsTarget, this.hitsCountTarget, this.paginationTarget]);
    //     this.showEl([this.contentTarget], "flex");
    //   }
    // }
  }

  _search(isIndexChanged) {
    const currentIndexName = this.indexName;
    const self = this;
    const key = this.indexRouteKey || "tout";
    const isGlobalState = key === "sorties" || key === "activites" || key === "lieux";

    this.search = instantsearch({
      urlSync: true,
      searchClient: this.searchClient,
      indexName: this.indexName,
      numberLocale: this.currentLocale,
      routing: {
        router: history({
          windowTitle({ q }) {
            const upperCasedKey = key.charAt(0).toUpperCase() + key.slice(1);
            const queryTitle = [q ? `Resultats pour "${q}"` : "Rechercher", upperCasedKey, "Paris.fr"]
              .filter((x) => x)
              .join(" - ");
            return queryTitle;
          },
          createURL({ qsModule, routeState, location }) {
            const baseUrl = self._getBaseUrl(location.href);

            const queryParameters = {};
            this.isToggleable = false;

            if (routeState.accessibilite?.length > 0) {
              queryParameters.accessibilite = routeState.accessibilite.join("+");
              this.isToggleable = true;
            }
            if (routeState.arrondissement?.length > 0) {
              queryParameters.arrondissement = self._getDistrictSlug(routeState.arrondissement);
              this.isToggleable = true;
            }
            if (routeState.arrondissements?.length > 0) {
              queryParameters.arrondissements = self._getDistrictSlug(routeState.arrondissements);
              this.isToggleable = true;
            }
            if (routeState.date) {
              queryParameters.date = self._formatedDate(routeState.date, "timestamp");
              this.isToggleable = true;
            }
            if (routeState.discipline?.length > 0) {
              queryParameters.discipline = self._getDisciplineSlug(routeState.discipline);
              this.isToggleable = true;
            }
            if (routeState.petits || routeState.enfants || routeState.jeunes || routeState.adultes) {
              const publicsSlugs = {
                petits: routeState.petits,
                enfants: routeState.enfants,
                jeunes: routeState.jeunes,
                adultes: routeState.adultes,
              };
              queryParameters.publics = self._getAudienceSlug(publicsSlugs);
              this.isToggleable = true;
            }
            if (routeState.gratuit) {
              if (routeState.gratuit === true) {
                queryParameters.gratuit = "oui";
                this.isToggleable = true;
              }
            }
            if (routeState.groupes?.length > 0) {
              queryParameters.groupes = self._getGroupSlug(routeState.groupes);
              this.isToggleable = true;
            }
            if (routeState.horaires?.length > 0) {
              queryParameters.horaires = routeState.horaires.join("+");
              this.isToggleable = true;
            }
            if (routeState.jours?.length > 0) {
              queryParameters.jours = routeState.jours.join("+");
              this.isToggleable = true;
            }
            if (routeState.lieu?.length > 0) {
              queryParameters.lieu = routeState.lieu[0].split("||")[1];
              this.isToggleable = true;
            }
            if (routeState.ouvert) {
              if (routeState.ouvert === true) {
                queryParameters.ouvert = "oui";
                this.isToggleable = true;
              }
            }
            if (routeState.page !== 1) {
              queryParameters.page = routeState.page;
            }
            if (routeState.q) {
              queryParameters.q = self._replaceSpacesByPlus(routeState.q);
              this.isToggleable = true;
            }
            if (routeState.tag?.length > 0) {
              queryParameters.tag = self._getTagSlug(routeState.tag);
              this.isToggleable = true;
            }
            if (routeState.types?.length > 0) {
              queryParameters.types = self._getTypeSlug(routeState.types);
              this.isToggleable = true;
            }
            if (routeState.univers?.length > 0) {
              queryParameters.univers = self._getUniverseSlug(routeState.univers);
              this.isToggleable = true;
            }

            const queryString = qsModule.stringify(queryParameters, {
              addQueryPrefix: true,
              arrayFormat: "repeat",
              encode: false,
            });
            self._topHitsNumber(this.isToggleable);

            return `${baseUrl}${key}${queryString}`;
          },
          parseURL({ qsModule, location }) {
            const {
              accessibilite = [],
              arrondissement = [],
              arrondissements = [],
              date = "",
              discipline = [],
              gratuit = "",
              groupes = [],
              horaires = [],
              jours = [],
              ouvert = "",
              page,
              publics = "",
              q = "",
              tag = [],
              types = [],
              univers = [],
            } = qsModule.parse(location.search.slice(1));

            const parseObject = {
              q: decodeURIComponent(q),
            };

            //Handle town hall district filter case
            if (!gon.mairie.global && isGlobalState) {
              parseObject.arrondissements = [];
            }
            const publicsObj = self._getAudienceParsedQuery(publics);

            if (!isIndexChanged) {
              parseObject.accessibilite = decodeURIComponent(accessibilite).split(" ");
              parseObject.adultes = publicsObj.adultes;
              parseObject.arrondissement = self._getDistrictName(arrondissement);
              parseObject.arrondissements = self._getDistrictName(arrondissements);
              parseObject.date = self._formatedDate(date, "date");
              parseObject.discipline = self._getDisciplineNames(discipline);
              parseObject.enfants = publicsObj.enfants;
              parseObject.gratuit = decodeURIComponent(gratuit);
              parseObject.groupes = self._getGroupNames(groupes);
              parseObject.horaires = decodeURIComponent(horaires).split(" ");
              parseObject.jeunes = publicsObj.jeunes;
              parseObject.jours = decodeURIComponent(jours).split(" ");
              parseObject.lieu = [self.placeName].filter((item) => item !== undefined);
              parseObject.ouvert = decodeURIComponent(ouvert);
              parseObject.page = page;
              parseObject.petits = publicsObj.petits;
              parseObject.tag = self._getTagNames(tag);
              parseObject.types = self._getTypeNames(types);
              parseObject.univers = self._getUniverseNames(univers);
            }
            return parseObject;
          },
        }),

        stateMapping: {
          // Defines how to go from InstantSearch’s internal state to a URL, and vice versa

          stateToRoute(uiState) {
            // Convert uiState into routeState
            const indexUiState = uiState[currentIndexName] || {};
            // console.log('indexUiState', indexUiState);
            return {
              accessibilite: indexUiState.refinementList?.accessibility?.filter((item) => item !== ""),
              adultes: indexUiState.toggle?.adults,
              arrondissement: indexUiState.refinementList?.district_name?.filter((item) => item !== ""),
              arrondissements: indexUiState.refinementList?.full_districts?.filter((item) => item !== ""),
              date: indexUiState.range?.date,
              discipline: indexUiState.refinementList?.discipline?.filter((item) => item !== ""),
              enfants: indexUiState.toggle?.children,
              gratuit: indexUiState.toggle?.free,
              groupes: indexUiState.refinementList?.political_group?.filter((item) => item !== ""),
              horaires: indexUiState.refinementList?.time_range?.filter((item) => item !== ""),
              jeunes: indexUiState.toggle?.teens,
              jours: indexUiState.refinementList?.weekday?.filter((item) => item !== ""),
              lieu: indexUiState.refinementList?.searchable_place?.filter((item) => item !== ""),
              ouvert: indexUiState.toggle?.is_open,
              page: indexUiState.page,
              petits: indexUiState.toggle?.toddlers,
              q: indexUiState.query,
              tag: indexUiState.refinementList?.["tags.name"]?.filter((item) => item !== ""),
              types: indexUiState.refinementList?.category_names?.filter((item) => item !== ""),
              univers: indexUiState.refinementList?.universe?.filter((item) => item !== ""),
            };
          },

          routeToState(routeState) {
            // Convert routeState into uiState
            // console.log('routeState', routeState);

            const routeObject = {
              query: routeState.q,
            };
            if (!gon.mairie.global && isGlobalState) {
              routeState.arrondissements.push(self._getCurrentTownHallIdSlug());
              routeObject.refinementList = {
                full_districts: routeState.arrondissements.filter((item) => item !== ""),
              };
            }
            if (!isIndexChanged) {
              (routeObject.page = routeState.page),
                (routeObject.refinementList = {
                  accessibility: routeState.accessibilite.filter((item) => item !== ""),
                  category_names: routeState.types.filter((item) => item !== ""),
                  discipline: routeState.discipline.filter((item) => item !== ""),
                  district_name: routeState.arrondissement.filter((item) => item !== ""),
                  full_districts: routeState.arrondissements.filter((item) => item !== ""),
                  political_group: routeState.groupes.filter((item) => item !== ""),
                  searchable_place: routeState.lieu.filter((item) => item !== ""),
                  "tags.name": routeState.tag.filter((item) => item !== ""),
                  universe: routeState.univers.filter((item) => item !== ""),
                  time_range: routeState.horaires.filter((item) => item !== ""),
                  weekday: routeState.jours.filter((item) => item !== ""),
                }),
                (routeObject.toggle = {
                  adults: routeState.adultes,
                  children: routeState.enfants,
                  free: routeState.gratuit,
                  is_open: routeState.ouvert,
                  teens: routeState.jeunes,
                  toddlers: routeState.petits,
                }),
                (routeObject.range = {
                  date: routeState.date,
                });
            }

            // console.log(routeState);
            return {
              [currentIndexName]: routeObject,
            };
          },
        },
      },
    });

    this.search.on("render", () => {
      this.updateAccessibility();
    });

    this.search.start();
  }

  updateAccessibility() {
    // Update searchBox buttons titles
    this.element.querySelector('.ais-SearchBox-submit').setAttribute('title', 'Rechercher');
    this.element.querySelector('.ais-SearchBox-reset').setAttribute('title', 'Réinitialiser');

    // Update pagination links
    const paginationLinks = [...this.element.querySelectorAll('.ais-Pagination-item--page > a')]
    if (paginationLinks.length > 0) {
      paginationLinks.forEach((link) => {
        link.removeAttribute('aria-current');
        link.removeAttribute('aria-label');
      })
    }

    const previousPageLink = this.element.querySelector('.ais-Pagination-item--previousPage > a');
    if (previousPageLink) {
      previousPageLink.setAttribute('aria-label', 'Page précédente');
    }

    const nextPageLink = this.element.querySelector('.ais-Pagination-item--nextPage > a');
    if (nextPageLink) {
      nextPageLink.setAttribute('aria-label', 'Page suivante');
    }

    const selectedPageLink = this.element.querySelector('.ais-Pagination-item--selected > a');
    if (selectedPageLink) {
      selectedPageLink.setAttribute('aria-current', 'page')
      selectedPageLink.setAttribute('aria-label', `${selectedPageLink.innerText} page active`);
    }

    // Add label to CurrentRefinements delete button
    this.element.querySelectorAll('.ais-CurrentRefinements-category').forEach(element => {
      element.querySelector('.ais-CurrentRefinements-delete')
        .setAttribute('aria-label', `Supprimer le filtre ${element.innerText.slice(0, -2)}`)
    });
  }
}
