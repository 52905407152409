import { Controller } from "stimulus";

import _get from "lodash/get";

export default class extends Controller {
  static targets = ["iframe", "cookies", "thumbnail", "cookiesCheckbox"];

  connect() {
    this._checkThumbnail();
  }

  displayIframe(event) {
    if (this.hasCookiesCheckboxTarget && this.cookiesCheckboxTarget.checked) {
      this.cookiesController = this.application.getControllerForElementAndIdentifier(document.body, "cookies");
      this.cookiesController.accept();
      return;
    }

    this.renderIframe(event);
  }

  renderIframe(event) {  
    if (!this.hasIframeTarget || !this.data.has("url")) {
      return;
    }    

    let iframeSrc = this.data.get("url");
    
    if (this.element.dataset.videoUrl.includes("instagram")) {
      this.element.classList.add("instagram-content");
    }
  
    // set autoplay if cookies are not accepted
    // N.B.: event type should be `cookies:accepted` if the cookie checkbox was checked
    if (event.type === "click") {
      this.iframeTarget.allow = "autoplay";

      if (/ina\.fr/.test(iframeSrc)) {
        iframeSrc = iframeSrc.replace(/0$/, "1");
      } else {
        iframeSrc += iframeSrc.indexOf("?") > 0 ? "&autoplay=1" : "?autoplay=1";
      }
    }

    this.iframeTarget.src = iframeSrc;
    this.iframeTarget.removeAttribute('tabindex')

    if (this.hasCookiesTarget) {
      this.element.classList.remove("has-cookies-message");
      this.element.removeChild(this.cookiesTarget);
    }
  }

  _checkThumbnail() {   
    if (this.element.dataset.originMeta || !this.hasCookiesTarget || 
        this.hasThumbnailTarget || !this.data.has("url")) {
      return;
    }

    this._fetchThumbnail(this.data.get("url"))
      .then(thumbnailSrc => {
        const imgElem = document.createElement("img");
        imgElem.src = thumbnailSrc;
        imgElem.classList.add("paris-cookies-thumbnail");
        imgElem.dataset.target = "video.thumbnail";
        this.cookiesTarget.insertBefore(imgElem, this.cookiesTarget.firstChild);
      })
      .catch(err => {
        console.error(err);
      });
  }

  _fetchThumbnail(url) {
    return new Promise((resolve, reject) => {
      const parsedUrl = document.createElement("a");
      parsedUrl.href = url;

      switch (true) {
        // YOUTUBE
        case /youtube\.com/.test(parsedUrl.href):
          // resolve("https://i.ytimg.com/vi/" + parsedUrl.pathname.split("/").pop() + "/sddefault.jpg"); // 640x480
          resolve("https://i.ytimg.com/vi/" + parsedUrl.pathname.split("/").pop() + "/hqdefault.jpg"); // 480x360
          break;
        // DAILYMOTION
        case /dailymotion\.com/.test(parsedUrl.href):
          resolve("https://www.dailymotion.com/thumbnail/video/" + parsedUrl.pathname.split("/").pop());
          break;
        // INA
        case /ina\.fr/.test(parsedUrl.href):
          resolve("https://www.ina.fr/images_v2/512x384/" + parsedUrl.pathname.split("/")[3] + ".jpeg");
          break;
        // VIMEO
        case /vimeo\.com/.test(parsedUrl.href):
          fetch("https://vimeo.com/api/v2/video/" + parsedUrl.pathname.split("/").pop() + ".json")
            .then(response => response.json())
            .then(data => {
              const thumbnailUrl = _get(data, "[0].thumbnail_large");

              if (thumbnailUrl) {
                resolve(thumbnailUrl);
              } else {
                reject();
              }
            })
            .catch(reject);
          break;
        // UNKNOWN
        default:
          reject(new Error("Unkown video provider"));
      }
    });
  }
}
