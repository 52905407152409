import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cards"];

  connect() {
    if (this.hasCardsTarget) {
      this.anchorController = this.application.getControllerForElementAndIdentifier(document.body, "anchor");
      this.anchorController.checkForMultipleLinktags(this.cardsTarget);
    }
  }
}
