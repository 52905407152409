import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["errorMessage"];

  initialize() {}

  open(e) {
    e.preventDefault();
    this.element.classList.add("is-open");
    this.emailInput?.focus();
  }

  confirm() {
    this.element.classList.add("is-confirmed");
  }

  get emailInput() {
    return this.element.querySelector('input[type="email"]');
  }
}
