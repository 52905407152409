import { Controller } from "stimulus";
import LazyLoad from "vanilla-lazyload";

export default class extends Controller {
  static targets = ["title", "image", "imageContainer"];

  initialize() {
    this.timeout = false;
    this.delay = 200;
  }

  connect() {
    this.initLazyLoad();
  }

  initLazyLoad() {
    this.lazyLoadInstance = new LazyLoad(
      {
        callback_loaded: el => this.handleTitle(el)
      },
      this.imageTargets
    );
  }

  handleTitle() {
    if (!this.hasTitleTarget || window.matchMedia("(max-width:800px)").matches) return;

    // const maxHeight = Math.min(el.height, el.offsetHeight, el.naturalHeight);
    // this.imageContainerTarget.style.maxHeight = `${maxHeight}px`;

    const elementHeight = this.element.clientHeight;
    const titleHeight = this.titleTarget.clientHeight;
    this.hasLongTitle = titleHeight / elementHeight > 0.6;

    this.element.classList.toggle("has-title-aligned-to-bottom", this.hasLongTitle);
  }

  stickTitleToBottom() {
    this.element.classList.add("has-title-aligned-to-bottom");
  }
}
