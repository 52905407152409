import { Controller } from "stimulus";

import { decodeMails } from "../../util/mailsHelper";

export default class extends Controller {
  static targets = ["textContainer"];

  connect() {
    decodeMails(this.hasTextContainerTarget, this.textContainerTarget);
  }
}
