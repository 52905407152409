import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["mairies"];

  onChange(e) {
    const select = e.target;
    let url = select.options[select.selectedIndex].value;

    if (url !== "") {
      window.location.href = url;
    }
  }
}
