import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import Flickity from "flickity";
import "flickity-imagesloaded";
import "flickity/dist/flickity.css";
import { hasDisabledAnimations } from "../../../app/assets/javascript/utils/a11y";

export default class extends Controller {
  static targets = ["content", "prevButton", "nextButton", "item"];

  initialize() {
    this.mediaQueryList = window.matchMedia("(max-width: 999px)");
    this.disableAnimations = hasDisabledAnimations();
  }

  connect() {
    if (this.isSmallAndMedium) {
      if (this.element.dataset.placeholderFor) {
        const canonicalTelex = document.getElementById(this.element.dataset.placeholderFor);
        if (canonicalTelex) {
          this.element.innerHTML = canonicalTelex.innerHTML;
          this.element.id = canonicalTelex.id;
          this.element.setAttribute("aria-labelledby", canonicalTelex.getAttribute("aria-labelledby"));
          this.element.setAttribute("aria-roledescription", "carousel");
          canonicalTelex.remove();
          this.itemTargets.forEach((item) => {
            item.setAttribute("role", "group");
            item.setAttribute("aria-roledescription", "slide");
            item.setAttribute("aria-label", item.getAttribute("data-label"));
            item.removeAttribute("data-label");
          });
        }
      }

      this.connectFlickity();
    }
  }

  disconnect() {
    if (this.isSmallAndMedium) {
      this.disconnectFlickity();
    }
  }

  connectFlickity() {
    this.disconnectFlickity();

    if (!this.hasContentTarget) return;

    if (this.isSmallAndMedium) {
      const imageElements = [...this.element.querySelectorAll("li img")];
      imageElements.forEach(this.replaceImageLazyLoadAttribute);
    }

    const globalOptions = {
      accessibility: true,
      cellAlign: "left",
      pageDots: false,
      prevNextButtons: false,
      imagesLoaded: true,
      lazyLoad: 2,
      on: {
        ready: this.onReady.bind(this),
        change: this.updateControls.bind(this),
      },
      contain: true,
    };

    const options = { ...globalOptions };

    this._flickityInstance = new Flickity(this.contentTarget, options);

    if (this.contentUrl) {
      this.flickity.on("settle", this.loadMoreContentIfNeeded.bind(this));
    }
  }

  disconnectFlickity() {
    if (this._flickityInstance) {
      this._flickityInstance.destroy();
    }
    this.element.classList.remove("telex-ready");
  }

  previous() {
    this.flickity.previous(undefined, this.disableAnimations);
  }

  next() {
    this.flickity.next(undefined, this.disableAnimations);
  }

  onReady() {
    this.updateControls();
    // We use this class to force height 100% on absolute slider cells
    setTimeout(() => {
      this.element.classList.add("telex-ready");
    }, 0);
  }

  updateControls() {
    const slides = this.flickity.slides;
    [this.prevButtonTarget, this.nextButtonTarget].forEach((button) => {
      // enable is wrapAround and at least 2 slides
      if (this.flickity.options.wrapAround && slides.length > 1) {
        button.disabled = false;
        return;
      }
      const lastIndex = slides.length ? slides.length - 1 : 0;
      const boundIndex = button == this.prevButtonTarget ? 0 : lastIndex;

      button.disabled = this.flickity.selectedIndex == boundIndex;
    });
  }

  loadMoreContentIfNeeded() {
    // We load more when we reach the penultimate group of slides
    const needed = this.flickity.selectedIndex >= this.flickity.slides.length - 2;

    if (needed) {
      this.loadMoreContent();
    }
  }

  loadMoreContent() {
    Rails.ajax({
      type: "GET",
      url: this.contentUrl,
      success: (response, _status, xhr) => {
        if (xhr.status === 204) {
          // We reached the end of the content, stop loading more
          this.stopLoadingMore();
        } else {
          if (this.shouldReplaceContent) {
            this.shouldReplaceContent = false;
            this.replaceContent(response);
          } else {
            this.appendContent(response);
          }
          this.updateControls();
        }

        // Read response headers
        this.setContentUrl(xhr.getResponseHeader("X-Next-Content-Url"));
      },
      error: (_response, _status, xhr) => {
        console.error(xhr.response);
      },
    });
  }

  replaceContent(response) {
    this.flickity.remove(this.flickity.getCellElements());
    this.appendContent(response);
    this.flickity.select(0, false, true);
    if (this.isSmallAndMedium) {
      const imageElements = [...response.querySelectorAll("li img")];
      imageElements.forEach(this.replaceImageLazyLoadAttribute);
    }
  }

  appendContent(response) {
    if (this.isSmallAndMedium) {
      const imageElements = [...response.body.querySelectorAll("li img")];
      imageElements.forEach(this.replaceImageLazyLoadAttribute);
    }
    this.flickity.append(response.body.childNodes);
  }

  replaceImageLazyLoadAttribute(image) {
    if (image.src) {
      // image.loading = "eager"
      image.setAttribute("data-flickity-lazyload", image.src);
      image.src = null;
    }
  }

  stopLoadingMore() {
    this.flickity.off("settle", this.loadMoreContentIfNeeded.bind(this));
  }

  setContentUrl(url) {
    if (!url) return;

    this.data.set("content-url", url);
  }

  get contentUrl() {
    return this.data.get("content-url");
  }

  get flickity() {
    return this._flickityInstance || Flickity.data(this.contentTarget);
  }

  get isSmallAndMedium() {
    return this.mediaQueryList.matches;
  }

  get items() {
    return this.element.querySelectorAll(".telex-item");
  }
}
