import "./section_component.scss";

import Rails from "@rails/ujs";
import { Controller as BaseController } from "stimulus";
import _debounce from "lodash/debounce";
import Flickity from "flickity";
import "flickity-imagesloaded";
import "flickity/dist/flickity.css";
import { hasDisabledAnimations } from "../../../assets/javascript/utils/a11y";

export class Controller extends BaseController {
  static targets = ["content", "prevButton", "nextButton", "reloadableButton"];

  initialize() {
    this.mediaQueryList = window.matchMedia("(max-width: 799px)");
    this.isPaused = false;
    this.tickerSpeed = 1;
    this.disableAnimations = hasDisabledAnimations();
  }

  connect() {
    this.connectFlickity();
  }

  disconnect() {
    this.disconnectFlickity();
  }

  connectFlickity() {
    if (!this.panning) return;

    this.disconnectFlickity();

    if (!this.hasContentTarget) return;

    const globalOptions = {
      accessibility: true,
      cellAlign: this.isSmallAndMedium ? "center" : "left",
      imagesLoaded: true,
      pageDots: false,
      prevNextButtons: false,
      on: {
        ready: this.updateControls.bind(this),
        change: this.updateControls.bind(this),
      },
    };
    let specificOptions;

    if (this.continuousPanning) {
      specificOptions = {
        cellAlign: "left",
        draggable: true,
        groupCells: 3,
        wrapAround: true,
      };
    } else {
      specificOptions = {
        contain: true,
        groupCells: true,
        lazyLoad: 2,
      };
    }

    const options = { ...globalOptions, ...specificOptions };

    this._flickityInstance = new Flickity(this.contentTarget, options);

    if (this.contentUrl) {
      this.flickity.on("settle", _debounce(this.loadMoreContentIfNeeded.bind(this), 300));
    }

    if (this.continuousPanning) {
      this.flickity.x = 0;

      this.items.forEach((i) => {
        i.addEventListener("mouseenter", () => this.pause(), false);
      });
      this.element.addEventListener("focusin", () => this.pause(), false);
      this.items.forEach((i) => {
        i.addEventListener("mouseleave", () => this.play(), false);
      });
      this.element.addEventListener("focusout", () => this.play(), false);

      this.flickity.on("dragStart", () => {
        this.isPaused = true;
      });

      this.pan();
    }
  }

  disconnectFlickity() {
    if (!this.panning) return;

    if (this._flickityInstance) {
      this._flickityInstance.destroy();
    }
  }

  previous() {
    this.flickity.previous(undefined, this.disableAnimations);
  }

  next() {
    this.flickity.next(undefined, this.disableAnimations);
  }

  pan() {
    if (this.isPaused) return;
    if (this.flickity.slides) {
      this.flickity.x = (this.flickity.x - this.tickerSpeed) % this.flickity.slideableWidth;
      this.flickity.selectedIndex = this.flickity.dragEndRestingSelect();
      this.flickity.updateSelectedSlide();
      this.flickity.settle(this.flickity.x);
    }
    window.requestAnimationFrame(() => this.pan());
  }

  pause() {
    this.isPaused = true;
  }

  play() {
    if (this.isPaused) {
      this.isPaused = false;
      window.requestAnimationFrame(() => this.pan());
    }
  }

  updateControls() {
    if (!this.panning) return;

    const slides = this.flickity.slides;
    [this.prevButtonTarget, this.nextButtonTarget].forEach((button) => {
      // enable is wrapAround and at least 2 slides
      if (this.flickity.options.wrapAround && slides.length > 1) {
        button.disabled = false;
        return;
      }
      const lastIndex = slides.length ? slides.length - 1 : 0;
      const boundIndex = button == this.prevButtonTarget ? 0 : lastIndex;

      button.disabled = this.flickity.selectedIndex == boundIndex;
    });
  }

  loadMoreContentIfNeeded() {
    // We load more when we reach the penultimate group of slides
    const needed = this.flickity.selectedIndex >= this.flickity.slides.length - 2;

    if (needed) {
      this.loadMoreContent();
    }
  }

  reloadContent(e) {
    const contentUrl = e.target.dataset.reloadContentUrl;

    this.setContentUrl(contentUrl);
    this.shouldReplaceContent = true;
    this.loadMoreContent();

    this.reloadButton(e.target.dataset);
  }

  reloadButton(data) {
    if (!this.hasReloadableButtonTarget) return;

    const { reloadButtonUrl, reloadButtonText } = data;

    if (reloadButtonUrl) {
      this.reloadableButtonTarget.href = reloadButtonUrl;
    }

    if (reloadButtonText) {
      this.reloadableButtonTarget.querySelector(".qfap--button-text").innerText = reloadButtonText;
    }
  }

  loadMoreContent() {
    Rails.ajax({
      type: "GET",
      url: this.contentUrl,
      success: (response, _status, xhr) => {
        if (xhr.status === 204) {
          // We reached the end of the content, stop loading more
          this.stopLoadingMore();
        } else {
          if (this.shouldReplaceContent) {
            this.shouldReplaceContent = false;
            this.replaceContent(response);
          } else {
            this.appendContent(response);
          }
          this.updateControls();
        }

        // Read response headers
        this.setContentUrl(xhr.getResponseHeader("X-Next-Content-Url"));
      },
      error: (_response, _status, xhr) => {
        console.error(xhr.response);
      },
    });
  }

  replaceContent(response) {
    if (this.panning) {
      this.flickity.remove(this.flickity.getCellElements());
      this.flickity.append(this.offsetEl);
      this.appendContent(response);
      this.flickity.select(0, false, true);
    } else {
      this.contentTarget.innerHTML = "";
      this.contentTarget.appendChild(this.offsetEl);
      this.appendContent(response);
    }
  }

  appendContent(response) {
    if (this.panning) {
      this.flickity.append(response.body.childNodes);
    } else {
      this.contentTarget.insertAdjacentHTML("beforeend", response.body.innerHTML);
    }
  }

  stopLoadingMore() {
    if (this.panning) {
      this.flickity.off("settle", this.loadMoreContentIfNeeded.bind(this));
    }
  }

  setContentUrl(url) {
    if (!url) return;

    this.data.set("content-url", url);
  }

  get contentUrl() {
    return this.data.get("content-url");
  }

  get panning() {
    return this.data.get("panning") != "false";
  }

  get continuousPanning() {
    return this.data.get("panning") == "continuous" && !this.isSmallAndMedium;
  }

  get flickity() {
    return this._flickityInstance || Flickity.data(this.contentTarget);
  }

  get offsetEl() {
    const el = document.createElement("div");
    el.classList.add("qfap--section-content-offset");
    return el;
  }

  get isSmallAndMedium() {
    return this.mediaQueryList.matches;
  }

  get items() {
    return this.element.querySelectorAll(".qfap--card, .qfap--tile");
  }
}
