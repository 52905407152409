import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["encodedContent", "cookies", "cookiesCheckbox"];

  displayHTML(event) {
    if (this.hasCookiesCheckboxTarget && this.cookiesCheckboxTarget.checked) {
      this.cookiesController = this.application.getControllerForElementAndIdentifier(document.body, "cookies");
      this.cookiesController.accept();
      return;
    }

    this.renderHTML(event);
  }

  renderHTML() {
    if (!this.hasEncodedContentTarget) {
      return;
    }

    this.encodedContentTarget.parentNode.replaceChild(this.encodedContentTarget.content, this.encodedContentTarget);

    if (this.hasCookiesTarget) {
      this.element.classList.remove("has-cookies-message");
      this.element.removeChild(this.cookiesTarget);
    }
  }
}
