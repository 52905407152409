/* global gon */

import { Controller } from "stimulus";
import Accordion from "@accede-web/accordion";

export default class extends Controller {
  static targets = ["textarea", "errors", "submit"];

  connect() {
    this.accordion = new Accordion(this.element);
    this.accordion.mount();
  }

  onPostSubmit = e => {
    e.preventDefault();

    fetch("/csrf_meta.json", {
      method: "GET"
    })
      .then(response => response.json())
      .then(data => {
        this.csrfToken = data.token;
        this._submitComment();
      });
  };

  // PRIVATE

  _submitComment() {
    fetch(this.data.get("route"), {
      method: "POST",
      body: JSON.stringify({
        comment: {
          content: this.textareaTarget.value,
          commentable_type: gon.commentable_type,
          commentable_id: gon.commentable_id
        }
      }),
      credentials: "include",
      dataType: "script",
      headers: {
        "X-CSRF-Token": this.csrfToken,
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          this.textareaTarget.setAttribute("readonly", true);
          this.errorsTarget.removeAttribute("role");
          this.errorsTarget.innerText = "";
          this.submitTarget.insertAdjacentHTML("afterend", this.confirmation);
          this.submitTarget.remove();
        } else if (data.status === "error") {
          this.errorsTarget.role = "alert";
          this.errorsTarget.innerHTML = data.message;
          this.errorsTarget.classList.remove("is-hidden");
        } else {
          this.errorsTarget.role = "alert";
          this.errorsTarget.innerHTML = "Erreur lors de l'envoi. Merci de bien vouloir réessayer plus tard.";
          this.errorsTarget.classList.remove("is-hidden");
        }
      });
  }

  get confirmation() {
    return `<p class="comment-confirmation">${this.data.get("confirmation")}</p>`;
  }
}
