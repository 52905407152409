import { Controller } from "stimulus";
import Accordion from "@accede-web/accordion";
import { decodeMails, listenLinks } from "../../util/mailsHelper";

export default class extends Controller {
  static targets = ["panel"];

  initialize() {
  }

  connect() {
    this.accordion = new Accordion(this.element);
    this.accordion.mount();

    this.decodeMailsInPanels();

    if (this.hasPanelTarget) {
      this.anchorController = this.application.getControllerForElementAndIdentifier(document.body, "anchor");
      this.anchorController.checkForMultipleLinktags(this.panelTarget);
    }
  }

  decodeMailsInPanels() {
    const promises = [...this.panelTargets].map(panel => this._createPromise(true, panel));

    Promise.all(promises).then(res => {
      res.forEach(r => {
        if (!r) return;

        r.target.innerHTML = r.result;
        listenLinks(r.target);
      });
    });
  }

  _createPromise(hasContent, panel) {
    return new Promise(resolve => resolve(decodeMails(hasContent, panel, false, true)));
  }
}
