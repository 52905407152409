/* global gon */
import { Controller } from "stimulus";
import algoliasearch from "algoliasearch";

export default class extends Controller {
  static targets = ["status", "statusMessage"];

  connect() {
    const id = parseInt(this.data.get("api-id"), 10);
    const client = algoliasearch(gon.algolia.application_id, gon.algolia.api_key);
    const index = client.initIndex(gon.algolia.indexes.places.name);

    index
      .getObject(id, {
        attributesToRetrieve: ["is_open", "open_details"]
      })
      .then(content => this.updateStatus(content));
  }

  updateStatus(content) {
    if (content.is_open === "unknown") {
      this.statusTarget.classList.add("is-closed");
      this.statusMessageTarget.innerHTML = "Horaires non renseignés";
      return;
    }

    this.statusTarget.classList.add(content.is_open ? "is-opened" : "is-closed");
    this.statusMessageTarget.innerHTML = content.open_details;
  }
}
