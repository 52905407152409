import { Controller } from "stimulus";

import { decodeMails } from "../../util/mailsHelper";

export default class extends Controller {
  static targets = ["liContainer"];

  connect() {
    decodeMails(this.hasLiContainerTarget, this.liContainerTarget);
    if (this.hasLiContainerTarget) {
      this.anchorController = this.application.getControllerForElementAndIdentifier(document.body, "anchor");
      this.anchorController.checkForMultipleLinktags(this.liContainerTarget);
    }
  }
}
