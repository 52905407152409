import application from "stimulus_application";
import { definitionsFromContext } from "stimulus/webpack-helpers";

// polyfills
import "whatwg-fetch";
import "promise-polyfill/src/polyfill";

import "./video.scss";

const context = require.context("./", true, /_controller\.js$/);
application.load(definitionsFromContext(context));
