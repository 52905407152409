import { Controller } from "stimulus";
import ResizeObserver from "resize-observer-polyfill";
import Flickity from "flickity";
import "flickity/dist/flickity.css";

export default class extends Controller {
  static targets = ["carousel", "previous", "next"];

  connect() {
    this.init();
  }

  init() {
    this.carousel = document.querySelector(".carousel");
    this.carouselLength = this.carouselTarget.querySelectorAll(".carousel-cell").length;
    this.max = this.carouselLength - 1;

    const nef = document.querySelector(".nef");
    this.savedNefstyle = nef.style.display;

    const resizeObserver = new ResizeObserver(() => this.repositionCaptions());
    resizeObserver.observe(this.carouselContainer);
  }

  initKeyboard() {
    document.onkeydown = (e) => {
      const event = e || window.event;

      if (event.key === "Escape") {
        this.toggleCarousel();
      }
    };
  }

  disposeKeyboard() {
    document.onkeydown = null;
  }

  initFocus() {
    document.addEventListener("focus", this.trapFocus.bind(this), true);
  }

  disposeFocus() {
    document.removeEventListener("focus", this.trapFocus.bind(this), true);

    if (this.opener) {
      this.opener.focus();
      this.opener = null;
    }
  }

  trapFocus(e) {
    if (this.element.contains(e.target)) {
      this.lastFocus = e.target;
      return;
    }

    if (this.lastFocus === this.lastFocusableElement) {
      e.preventDefault();
      this.firstFocusableElement.focus();
    } else if (this.lastFocus === this.firstFocusableElement) {
      e.preventDefault();
      this.lastFocusableElement.focus();
    }
  }

  initFlickity() {
    this.flkty = new Flickity(this.carousel, {
      prevNextButtons: false,
      pageDots: false,
      imagesLoaded: true,
      accessibility: true,
      draggable: true,
      setGallerySize: false,
      resize: true,
    });

    this.flkty.focus();

    this.flkty.on("change", (index) => {
      this.checkButtonAvailability(index, this.max);
    });
  }

  reset() {
    this.flkty.destroy();
    this.flkty = null;
    this.disposeKeyboard();
    this.disposeFocus();
  }

  prev() {
    this.flkty.next();
  }

  next() {
    this.flkty.previous();
  }

  selectCell(id) {
    this.initFlickity();
    const selector = `.carousel-cell[data-id="${id}"]`;
    const cell = document.querySelector(selector);
    const index = cell.dataset.index;
    this.flkty.selectCell(selector, false, true);
    this.checkButtonAvailability(index, this.max);
  }

  checkButtonAvailability(index, max) {
    this.previousTarget.disabled = (Number(index) === 0);
    this.nextTarget.disabled = (Number(index) === max);
  }

  repositionCaptions() {
    // Note: Get all imgs on cells then remove css property to get real relative position,
    // (cause object-fit property always return left:0),
    // reposition image component footer, and set back styles.
    const imgs = document.querySelectorAll(".carousel-cell-content .image-img img");
    imgs.forEach((img) => {
      if (img) {
        const caption = img.parentNode.parentNode.querySelector(".image-footer");
        // Only if caption exist
        if (caption) {
          img.style.objectFit = "unset";
          img.style.width = "unset";
          if (img.offsetLeft < 0) {
            caption.style.left = 0;
          } else {
            caption.style.left = `${img.offsetLeft}px`;
          }

          img.style.objectFit = "contain";
          img.style.width = "100%";
        }
      }
    });
  }

  toggleCarousel(e) {
    let card;
    if (e) {
      card = e.target.closest("button");
    }

    const container = document.querySelector("#carousel-container");
    const mainContainer = document.querySelector(".portfolio-carousel-main-container");
    const headingTitle = document.querySelector(".heading-title");
    const body = document.querySelector("body");
    const nef = document.querySelector(".nef");

    if (!headingTitle) {
      return false;
    }

    container.classList.toggle("show");
    mainContainer.classList.toggle("is-shown");
    nef.classList.toggle("is-hide");

    if (container.classList.contains("show")) {
      this.storeOpener();
      this.initKeyboard();
      this.initFocus();

      body.style.overflow = "hidden";
      if (card) {
        this.selectCell(card.dataset.id);
      }
    } else {
      this.reset();
      body.style.overflow = "auto";
    }
  }

  storeOpener() {
    // store the current focused element before focusing the layer
    this.opener = this.opener || this.activeElement || document.activeElement;
  }

  get carouselContainer() {
    return document.querySelector(".portfolio-carousel-main-container");
  }

  get focusableElements() {
    return this.element.querySelectorAll(
      "a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled])"
    );
  }

  get firstFocusableElement() {
    const focusableElements = this.focusableElements;
    return focusableElements[0];
  }

  get lastFocusableElement() {
    const focusableElements = this.focusableElements;
    return focusableElements[focusableElements.length - 1];
  }
}
