/* global gon */
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "button",
    "commentTextarea",
    "commentTitle",
    "commentSubmit",
    "commentErrors",
    "commentCancel",
    "commentBtn",
    "yesBtn",
    "noBtn",
    "commentForm",
  ];

  connect() {
    this.checkEmailOrPhonePresence();
    this.config = {
      method: "POST",
      credentials: "include",
      dataType: "script",
      body: {},
      headers: {
        "X-CSRF-Token": null,
        "Content-Type": "application/json",
      },
    };
  }

  submitFeedback(e) {
    e.preventDefault();
    const target = e.target;

    if (target.dataset.value === "comment") this.disableButtons([this.commentBtnTarget]);
    else {
      this.disableButtons([this.yesBtnTarget, this.noBtnTarget, this.commentBtnTarget]);
      this._getCsrfToken().then(() => this._submitFeedback(target.dataset.value));
    }

    target.classList.add("is-active");
    this.element.classList.add("has-comment-form");
    this.commentTitleTarget.innerText = target.dataset.commentTitle;
  }

  cancelComment() {
    this.element.classList.remove("has-comment-form");
    this.disableButtons([this.commentBtnTarget]);
    this.commentFormTarget.remove();
    if (this.hasYesBtnTarget) {
      this.yesBtnTarget.focus();
    }
  }

  disableButtons(buttons) {
    buttons ||= this.buttonTargets;
    buttons.forEach((b) => (b.disabled = true));
  }

  checkEmailOrPhonePresence() {
    const text = this.commentTextareaTarget.value;
    const phoneRegex = /((\+|00)[\d]+[\s.-]?|0)([1-9])(([\s.-]?\d{2}){4}|\d{2}([\s.-]?\d{3}){2})/;
    const mailRegex =
      /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
    const warning = mailRegex.test(text) || phoneRegex.test(text);

    this.element.classList.toggle("has-comment-warning", warning);
    this.commentSubmitTarget.disabled = warning;
  }

  onPostSubmit = (e) => {
    e.preventDefault();
    this._getCsrfToken().then(() => this._submitComment());
  };

  _getCsrfToken() {
    return new Promise((resolve) => {
      fetch("/csrf_meta.json", { method: "GET" })
        .then((response) => response.json())
        .then((data) => {
          this.config.headers["X-CSRF-Token"] = data.token;
          resolve();
        });
    });
  }

  _submitComment() {
    this.config.body = JSON.stringify({
      comment: {
        content: this.commentTextareaTarget.value,
        commentable_type: gon.commentable_type,
        commentable_id: gon.commentable_id,
      },
    });
    this._fetchData(this.data.get("comment-route"));
  }

  _submitFeedback(feedback) {
    this.config.body = JSON.stringify({
      feedback: {
        value: feedback,
        content_type: gon.commentable_type,
        page_id: gon.commentable_id,
      },
    });

    this._fetchData(this.data.get("feedback-route"));
  }

  _fetchData(route) {
    fetch(route, this.config)
      .then((response) => response.json())
      .then((data) => this._handleFetchResponse(data));
  }

  _handleFetchResponse(data) {
    if (data.status === "success") {
      this.commentErrorsTarget.removeAttribute("role");
      this.commentErrorsTarget.innerText = "";
      if (data.origin === "feedback") return;
      this.commentTextareaTarget.setAttribute("readonly", true);
      this.commentTextareaTarget.insertAdjacentHTML(
        "afterend",
        `<div class="feedback-comment-success" role="status">${this.data.get("confirmation")}</div>`
      );
      this.commentSubmitTarget.remove();
      this.commentCancelTarget.remove();
      this.commentTextareaTarget.value = "";
      this.commentTextareaTarget.remove();
    } else if (data.status === "error") {
      this.commentErrorsTarget.role = "alert";
      this.commentErrorsTarget.innerHTML = data.message;
      this.commentErrorsTarget.classList.remove("is-hidden");
    } else {
      this.commentErrorsTarget.role = "alert";
      this.commentErrorsTarget.innerHTML = "Erreur lors de l'envoi. Merci de bien vouloir réessayer plus tard.";
      this.commentErrorsTarget.classList.remove("is-hidden");
    }

    this.config.body = {};
  }
}
