import { Controller } from "stimulus";

export default class extends Controller {
  open(e) {
    e.preventDefault();
    e.stopPropagation();
    const link = e.target.closest("a");
    const url = link.getAttribute("href");
    this.openInNewWindow(url);
  }

  openInNewWindow(url) {
    const newWindow = window.open(url, "share", "height=400,width=600");
    if (window.focus) {
      newWindow.focus();
    }
  }
}
