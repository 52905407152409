import { Controller } from "stimulus";

import { decodeMails } from "../../util/mailsHelper";

export default class extends Controller {
  static targets = ["url"];

  connect() {
    if (this.hasUrlTarget) {
      const href = this.urlTarget.getAttribute("href");
      if (href.startsWith("#")) {
        this.urlTarget.onclick = e => {
          e.preventDefault();
          this.anchorController = this.application.getControllerForElementAndIdentifier(document.body, "anchor");
          this.anchorController.scrollToAnchor(href);
        };
      } else decodeMails(this.hasUrlTarget, this.urlTarget, true);
    }
  }
}
