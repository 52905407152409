import { Controller } from "stimulus";
import Flickity from "flickity";
import "flickity/dist/flickity.css";

export default class extends Controller {
  static targets = ["cards"];

  initialize() {
    this.flkty = null;
    this.timeout = false;
    this.delay = 250;
  }

  connect() {
    this.handleFlickity();
    window.addEventListener("resize", () => this.handleResize());
  }

  handleResize() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.handleFlickity(), this.delay);
  }

  handleFlickity() {
    if (this.flkty) {
      this.flkty.destroy();
    }

    if (!window.matchMedia("(max-width:800px)").matches) return;

    this.flkty = new Flickity(this.cardsTarget, {
      prevNextButtons: false,
      pageDots: false,
      imagesLoaded: true,
      accessibility: true,
      cellAlign: 'left'
    });
  }
}
