import { connectRefinementList } from "instantsearch.js/es/connectors";
import icon from "../../../icon/helper";

const renderRefinementList = (renderOptions, isFirstRender) => {
    const {
      items,
      refine,
      widgetParams,
    } = renderOptions;
  
    if (isFirstRender) {
        const fragment = document.createRange().createContextualFragment(`
        <ul class="ais-RefinementList-list ${widgetParams.cssClasses?.list}">
        </ul>
      `);
      widgetParams.container.appendChild(fragment);
    }
    
    widgetParams.container.querySelector('ul').innerHTML = items
      .map(
        item => `
        <li class="ais-RefinementList-item">
            <div>
                <label class="ais-RefinementList-label">
                    <input type="checkbox" class="ais-RefinementList-checkbox" value="${item.value}" ${item.isRefined ? 'checked' : ''}>
                    <span class="ais-RefinementList-labelText">${widgetParams.icon ? icon("accessibility/" + item.value) : ""} ${item.label} ${widgetParams.count ? `<span class='ais-RefinementList-count'>(${item.count})</span>` : ""}</span>
                </label>
            </div>
        </li>
        `
      )
      .join('');
  
    [...widgetParams.container.querySelectorAll('.ais-RefinementList-checkbox')].forEach(element => {
      element.addEventListener('click', event => {
        event.preventDefault();
        refine(event.currentTarget.value);
      });
    });

  };

export default connectRefinementList(renderRefinementList);