import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["encodedContent", "cookies", "cookiesCheckbox"];

  // LIFECYCLE

  // connect() {}

  // ACTIONS

  displayIframe(event) {
    if (this.hasCookiesCheckboxTarget && this.cookiesCheckboxTarget.checked) {
      this.cookiesController = this.application.getControllerForElementAndIdentifier(document.body, "cookies");
      this.cookiesController.accept();
      return;
    }

    this.renderIframe(event);
  }

  renderIframe() {
    if (!this.hasEncodedContentTarget) {
      return;
    }

    this.encodedContentTarget.parentNode.replaceChild(this.encodedContentTarget.content, this.encodedContentTarget);

    if (this.hasCookiesTarget) {
      this.element.classList.remove("has-cookies-message");
      this.element.removeChild(this.cookiesTarget);
    }

    const iframe = this.element.querySelector("iframe");
    const originalHeight = iframe.getAttribute("data-original-height");

    if (this.element.classList.contains("is-video") && originalHeight) {
      this._custom_ratio(iframe, originalHeight);
    }
  }

  // PRIVATE

  _custom_ratio(iframe, originalHeight) {
    const ratio = ((parseInt(originalHeight) / 800) * 100).toFixed(2);
    this._defaultIframeAttributes(iframe);
    this.element.setAttribute("style", `padding-top: calc(${ratio}% + 20px);`);
  }

  _defaultIframeAttributes(iframe) {
    [
      { height: "100%" },
      { width: "100%" },
      { scrolling: "no" },
      { frameborder: 0 },
      { marginheight: 0 },
      { marginwidth: 0 },
    ].forEach((attr) => iframe.setAttribute(Object.keys(attr), Object.values(attr)));
  }
}
